<template>
   <div class="page_section">
      <MobileTitle :title="banner.title"/>
      <Banner :content="banner"/>

      <div class="container">
         <div class="breadcrumb_section">
            <ul>
               <li>
                  <router-link :to="{name: 'home', params: {locale: localeRouteParams}}">
                     {{ __t('homepage') }}
                  </router-link>
               </li>
               <li class="active">{{ banner.title }}</li>
            </ul>
         </div>
      </div>

      <TrafficInformationMessage/>

      <Footer/>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import Footer from "../_common/Footer";
   import MobileTitle from "../_common/MobileTitle";
   import Banner from "../_content_elements/Banner";
   import TrafficInformationMessage from "../_content_elements/TrafficInformationMessage";

   export default {
      name: "ConstructionWorkMessage",
      components: {
         Banner,
         Footer,
         MobileTitle,
         TrafficInformationMessage
      },
      data() {
         return {
            banner: {
               title: 'Aktuelle Meldungen',
               image: '/abellio_banner.jpg',
               smallBanner: false
            }
         }
      },
      computed: {
         ...mapState([
            'page',
            'locales',
            'settings'
         ]),
         localeRouteParams() {
            return (this.locales.currentLocale != this.locales.defaultLocale) ? this.locales.currentLocale : null
         }
      }
      // created() {
      //    this.$store.dispatch('page/getBySlug', 'single-aktuelle-meldung').then(() => {
      //       this.pageLoaded = true;
      //    }, (error) => {
      //       if (error.response.status === 404) {
      //          this.$router.replace({
      //             name: '404', params: {
      //                locale: this.$route.params.locale ? this.$route.params.locale : null
      //             }
      //          });
      //       }
      //    });
      // },
      // beforeDestroy() {
      //    this.$store.commit('page/setBySlug', {});
      // }
   }
</script>

<style lang="scss" scoped>
</style>
